class Zeit {
    lastMinute;
    startseiteElement = document.querySelectorAll(".startseite__startseite");

    constructor(querySelector) {
        this.querySelector = querySelector;
        this.div = document.querySelector(this.querySelector);
        this.startzeit = document.querySelector(".startseite__startseite").dataset.startzeit;
        this.endzeit = document.querySelector(".startseite__startseite").dataset.endzeit;
    }

    get neueZeit() {
        var date = new Date();
        var minute = date.getMinutes();
      
        if (minute !== this.lastMinute) {
          this.lastMinute = minute;
      
          const zeitElemente = document.querySelectorAll(this.querySelector);
      
          for (const zeitElement of zeitElemente) {
            zeitElement.textContent = date.toLocaleTimeString('de-CH', {
              hour: '2-digit',
              minute: '2-digit'
            }) + " Uhr";
          }
        }
    }

    isoZeitZuSekunden(iso) {
        const isoArray = iso.split(":");
        return (parseInt(isoArray[0], 10) * 60 * 60) + (parseInt(isoArray[1], 10) * 60) + parseInt(isoArray[2], 10);
    }

    aktualisiereStatus() {
        var date = new Date();
        const jetzt = this.isoZeitZuSekunden(date.toLocaleTimeString('de-CH'));

        for (const zeitElement of this.startseiteElement) {
            let startzeit = this.isoZeitZuSekunden(zeitElement.dataset.startzeit);
            let endzeit = this.isoZeitZuSekunden(zeitElement.dataset.endzeit);

            if(endzeit < startzeit) {
                if (jetzt < startzeit && jetzt <= endzeit) {
                    zeitElement.dataset.status = "aktiv";  
                } else if (jetzt > startzeit && jetzt >= endzeit) {
                    zeitElement.dataset.status = "aktiv";  
                } else {
                    zeitElement.dataset.status = "inaktiv";
                }
            } 
            else if (jetzt >= startzeit && jetzt <= endzeit) {
                zeitElement.dataset.status = "aktiv";
            } else {
                zeitElement.dataset.status = "inaktiv";
            }
        }
    }
}

const zeitElement = new Zeit('.startseite__zeit--zeit');

window.onload = () => {
    zeitElement.neueZeit;
    zeitElement.aktualisiereStatus();
};

setInterval(() => {
    zeitElement.neueZeit;
    zeitElement.aktualisiereStatus();
}, 1000);

